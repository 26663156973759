import React from "react"
import "../css/site.css"

const AboutLanyard = () => {
  return (
    <div>
      <h1>AboutLanyard page</h1>
    </div>
  )
}

export default AboutLanyard
